import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoginData } from "../types";
import {
  confirm,
  getUser,
  login,
  logout,
  signup,
  verify,
} from "../api/repository";
import { getAccountsThunkAction } from "./accountSlice";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    loading: false,
    token: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserThunkAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserThunkAction.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginThunkAction.pending, (state) => {
        state.loading = true;
        state.user = null;
      })
      .addCase(loginThunkAction.fulfilled, (state: any, action) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(loginThunkAction.rejected, (state: any, action) => {
        state.loading = false;
        state.user = null;
      })
      .addCase(logoutThunkAction.fulfilled, (state: any) => {
        state.user = null;
      })
      .addCase(signupThunkAction.fulfilled, (state: any, action) => {
        state.token = action.payload;
      })
      .addCase(verifyThunkAction.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(confirmThunkAction.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(recoverThunkAction.fulfilled, (state: any, action) => {
        state.token = action.payload;
      })
      .addCase(verifyRecoverThunkAction.fulfilled, (state, action) => {
        state.token = action.payload;
      })
      .addCase(confirmRecoverThunkAction.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  },
});

export const loginThunkAction = createAsyncThunk(
  "user/login",
  async (data: LoginData, { dispatch }) => {
    const response: any = await login({ ...data });
    if (response.status === 200) {
      dispatch(getAccountsThunkAction());
      return response.data.data.user;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const signupThunkAction = createAsyncThunk(
  "user/signup",
  async (phoneNumber: number, { dispatch }) => {
    const response: any = await signup(phoneNumber);
    if (response.status === 200) {
      return response.data.data.token;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const verifyThunkAction = createAsyncThunk(
  "user/verify",
  async (code: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const response: any = await verify(code, state.user.token);
    if (response.status === 200) {
      return response.data.data.token;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const confirmThunkAction = createAsyncThunk(
  "user/confirm",
  async (password: string, { dispatch, getState }) => {
    let state: any = getState();
    const response: any = await confirm(password, state.user.token);
    if (response.status === 200) {
      dispatch(getAccountsThunkAction());
      return response.data.data.user;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const recoverThunkAction = createAsyncThunk(
  "user/recover",
  async (phoneNumber: number) => {
    const response: any = await signup(phoneNumber, true);
    if (response.status === 200) {
      return response.data.data.token;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const verifyRecoverThunkAction = createAsyncThunk(
  "user/verifyRecover",
  async (code: string, thunkAPI) => {
    const state: any = thunkAPI.getState();
    const response: any = await verify(code, state.user.token, true);
    if (response.status === 200) {
      return response.data.data.token;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const confirmRecoverThunkAction = createAsyncThunk(
  "user/confirmRecover",
  async (password: string, { dispatch, getState }) => {
    let state: any = getState();
    const response: any = await confirm(password, state.user.token, true);
    if (response.status === 200) {
      dispatch(getAccountsThunkAction());
      return response.data.data.user;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const logoutThunkAction = createAsyncThunk("user/logout", async () => {
  const response: any = await logout();
  if (response.status === 200) {
  } else {
    throw new Error(response.response.data.error.title);
  }
});

export const getUserThunkAction = createAsyncThunk("user/getUser", async () => {
  const response: any = await getUser();
  if (response.status === 200) {
    return response.data.data.user;
  } else {
    throw new Error(response.response.data.error.title);
  }
});

export default userSlice.reducer;
