import { Button, Input, message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  verifyRecoverThunkAction,
  verifyThunkAction,
} from "../store/userSlice";
import { useNavigate } from "react-router-dom";

export default function VerifyPage({ recover = false }) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [code, setCode] = useState<any>();

  const handleCodeSubmit = () => {
    dispatch(verifyThunkAction(code))
      .unwrap()
      .then(() => navigate("/auth/signup/confirm"))
      .catch((err: Error) => {
        message.error(err.message);
      });
  };

  const handleRecoverCodeSubmit = () => {
    dispatch(verifyRecoverThunkAction(code))
      .unwrap()
      .then(() => navigate("/auth/recover/confirm"))
      .catch((err: Error) => {
        message.error(err.message);
      });
  };

  return (
    <div style={{ height: "100svh" ,  width: "100%" , display: "flex", justifyContent:"center" , alignItems:"center"}}>
      <div className="form">
        <h1 className="form__title">СМС Код</h1>

        <p className="name-info">Введите код полученный в СМС сообщении</p>
        <div className="login-page__form-lk">
        <Input className="form__input" placeholder="Код" onChange={(v) => setCode(v.target.value)} />
        <Button className="form__button"
          onClick={() =>
            recover ? handleRecoverCodeSubmit() : handleCodeSubmit()
          }
        >
          Подтвердить
        </Button>
        </div>
      </div>
    </div>
  );
}
