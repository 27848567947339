import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history } from "../api/repository";
import { HistoryData } from "../models/history-data";

export interface IOrderState extends HistoryData {
  loading: boolean,
};

const initialState: IOrderState = {
  loading: false,
}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(historyThunkAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(historyThunkAction.fulfilled, (state: IOrderState, action) => {
        state.loading = false;
        state.history = action.payload?.history;
      })
      .addCase(historyThunkAction.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const historyThunkAction = createAsyncThunk("orders/history", async () => {
  const response = await history();
  return response.data.data;
});

export default orderSlice.reducer;
