/* tslint:disable */
/* eslint-disable */
/**
 * REST API ЛК МУП \"Нальчикский водоканал\"
 * Описание REST API запросов web-версии личного кабинета МУП \"Нальчикский водоканал\"
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

 /**
 * Статус платежа
 *
 * @export
 * @interface Status
 */
export interface Status {

    /**
     * Код
     *
     * @type {string}
     * @memberof Status
     */
    code: StatusCodeEnum;

    /**
     * Сообщение
     *
     * @type {string}
     * @memberof Status
     */
    message: string;
}

/**
 * @export
 * @enum {string}
 */
export enum StatusCodeEnum {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

