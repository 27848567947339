import {
  DeleteOutlined,
  LogoutOutlined,
  PlusOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Input, Layout, Menu, message, Modal, Tooltip } from "antd";
import Sider from "antd/es/layout/Sider";
import { Content, Footer, Header } from "antd/es/layout/layout";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Account, User } from "../types";
import { logoutThunkAction } from "../store/userSlice";
import {
  addAccountThunkAction,
  deleteAccountThunkAction,
  getAccountsThunkAction,
  setCurrent,
  setModal,
} from "../store/accountSlice";
import { useEffect, useState } from "react";
import Logo from "./logoLayout.svg";

export default function MainLayout() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const account = useSelector((state: any) => state.account);
  const [accNumber, setAccNumber] = useState<any>("");

  useEffect(() => {
    (async () => {
      return await dispatch(getAccountsThunkAction())
        .unwrap()
        .catch((err: Error) => message.error(err.message));
    })();
  }, []);

  return (
    <Layout className="layout">
      <Sider
        className="sider"
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        // onCollapse={() => setIsContentOpen(!isContentOpen)}
      >
        <a href="/" style={{}}>
          <img className="layout_logo" src={Logo} alt="logo" />
        </a>
        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={["1"]}
          items={[
            {
              key: "1",
              icon: <UserOutlined />,
              label: "Лицевой счет",
              // label: account.accounts[0].uin,
              children: [
                ...account.accounts.map((acc: Account, index: number) => {
                  return {
                    key: `a${index}`,
                    label: (
                      <Tooltip
                        placement="right"
                        title={
                          <Button
                            type="text"
                            icon={<DeleteOutlined />}
                            style={{ color: "white" }}
                            onClick={() =>
                              dispatch(deleteAccountThunkAction(acc.uin))
                            }
                          >
                            Удалить
                          </Button>
                        }
                      >
                        {acc.uin}
                      </Tooltip>
                    ),
                    className:
                      account.current?.uin === acc.uin
                        ? "selected-account"
                        : "",
                    onClick: () => {
                      navigate("home");
                      dispatch(setCurrent(index));
                      navigate("/home");
                    },
                  };
                }),
                {
                  key: "ab",
                  label: "Добавить",
                  icon: <PlusOutlined />,
                  onClick: () => dispatch(setModal(true)),
                },
              ],
            },
            {
              key: "2",
              icon: <UnorderedListOutlined />,
              label: "Платежи",
              onClick: async () => {
                navigate("/history");
              },
            },
            {
              key: "3",
              icon: <LogoutOutlined />,
              label: "Выйти",
              onClick: async () => {
                await dispatch(logoutThunkAction());
                navigate("/auth/login");
              },
            },
          ]}
        />
      </Sider>
      <Layout>
        <Modal
          title="Добавление лицевого счета"
          open={account.addAccountModal}
          onOk={() => dispatch(setModal(false))}
          onCancel={() => dispatch(setModal(false))}
          cancelText="Отмена"
          okText="Добавить"
          okButtonProps={{
            onClick: () => {
              dispatch(addAccountThunkAction(accNumber))
                .unwrap()
                .then(() => {
                  setAccNumber("");
                  dispatch(setModal(false));
                })
                .catch((err: Error) => {
                  message.error(err.message);
                });
            },
          }}
        >
          <Input
            maxLength={7}
            type="number"
            value={accNumber}
            onChange={(v) => setAccNumber(v.target.value)}
          />
        </Modal>
        <Content style={{ margin: "10px 5px 0" }}>
          <div
            style={{
              padding: 5,
              minHeight: 360,
              background: "white",
              borderRadius: "white",
            }}
            className="open"
          >
            <Outlet />
          </div>
        </Content>
        <Footer style={{ textAlign: "center" }} className="open">
          МУП "Нальчикский водоканал" {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}
