import { useDispatch, useSelector } from "react-redux";
import { Account, User, Accruals } from "../types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Empty, Input, InputNumber, Typography } from "antd";
import { registerPayment } from "../api/repository";
import { setModal } from "../store/accountSlice";

export default function HomePage() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const user: User = useSelector((state: any) => state.user.user);
  const account = useSelector((state: any) => state.account);
  const currentAccount: Account = account.current;

  const [amount, setAmount] = useState<number>(currentAccount ? currentAccount.amount / 100 : 0);

  const onPayment = async () => {
    await registerPayment(currentAccount!.uin, amount * 100).then(
      (res: any) => {
        window.open(res.data.data.billingUrl);
      }
    );
  };

  if (account.accounts.length === 0) {
    return (
      <Empty
        image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
        imageStyle={{ height: 60 }}
        description={
          <Typography.Text>
            <div>У Вас нет подключенных лицевых счетов</div>
            <div>
              Добавьте Ваш первый лицевой счет для дальнейшей работы в личном
              кабинете
            </div>
          </Typography.Text>
        }
      >
        <Button type="primary" onClick={() => dispatch(setModal(true))}>
          Добавить
        </Button>
      </Empty>
    );
  }
  if (!account.loading) {
    return (
      <div>
        <div className="container_lk-info">
          <div className="container-info-content">
            <Card title={"ЛС № " + currentAccount.uin} className="content">
              <p className="name-info mobail_stapn_info">
                ФИО: <span className="span-info">{currentAccount.fio}</span>
              </p>
              <p className="name-info mobail_stapn_info">
                Адрес: <span className="span-info">{currentAccount.address}</span>
              </p>
              <p className="name-info">
                Прописанных:
                <span className="span-info"> {currentAccount.men}</span>
              </p>
              <p className="name-info">
                Задолженность:
                <span className="span-info">
                  {" "}
                  {(currentAccount.amount / 100).toLocaleString("ru-RU")}
                </span>
              </p>
              <p className="name-info">
                Площадь:<span className="span-info"> {currentAccount.area}</span>
              </p>
              <p className="name-info">
                Период:<span className="span-info"> {currentAccount.period}</span>
              </p>
            </Card>
            <Card title={"Баланс"} className="content">
              {currentAccount.accruals.map((accrual: Accruals) => {
                return (
                  <p className="name-info">
                    {accrual.title}: <span className="span-info">{accrual.value}</span>
                  </p>
                );
              })}
            </Card>
          </div>
          <Card title="Оплата" className="content">
            <div className="login-page__form-lk">
              <InputNumber
                value={currentAccount.amount / 100 > 0 ? currentAccount.amount / 100 : 0}
                min={0}
                max={200000}
                controls={false}
                /*status={amount == null || amount < 0.01 ? "error" : ""}*/
                onChange={(value) => setAmount(value!)}
                suffix="₽"
                className="form__input"
              />
              <Button className="form__button" /*disabled={amount == null || amount < 0.01}*/ onClick={() => onPayment()}>
                Оплатить
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  } else {
    return <p>Загрузка</p>;
  }
}
