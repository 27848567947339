import { Button, Input, message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { recoverThunkAction, signupThunkAction } from "../store/userSlice";
import MaskedInput from "antd-mask-input";

export default function SignUpPage({ recover = false }) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [phone, setPhone] = useState<any>();

  const handleSignUp = async (phone: string) => {
    await dispatch(signupThunkAction(Number(phone)))
      .unwrap()
      .then(() => navigate(`/auth/${recover ? "recover" : "signup"}/verify`))
      .catch((err: Error) => {
        message.error(err.message);
      });
  };

  const handleRecover = (phone: string) => {
    dispatch(recoverThunkAction(Number(phone)))
      .unwrap()
      .then(() => navigate(`/auth/${recover ? "recover" : "signup"}/verify`))
      .catch((err: Error) => {
        message.error(err.message);
      });
  };

  return (
    <div
      style={{
        height: "100svh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="form">
        <h1 className="form__title">{recover ? "Восстановление пароля" : "Регистрация"}</h1>
        <div className="login-page__form">
          <MaskedInput
            mask={"+7 (000) 000-00-00"}
            className="form__input"
            placeholder="Логин"
            onChange={(v) => setPhone(v.unmaskedValue)}
          />
          <Button
            className="form__button"
            onClick={() =>
              recover ? handleRecover(phone) : handleSignUp(phone)
            }
          >
            Получить код
          </Button>
        </div>
        <div className="reg-bottom_input">
          <span className="reg-bottom-span">
            Если вы зарегистрированны, пожалуйста войдите
          </span>
          <Link className="form__button" to="/auth/login">
            Вход
          </Link>
        </div>

        <Outlet />
      </div>
    </div>
  );
}
