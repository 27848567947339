import { Input, Button, Tooltip, message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  confirmRecoverThunkAction,
  confirmThunkAction,
} from "../store/userSlice";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";

export default function ConfirmPage({ recover = false }) {
  const dispatch = useDispatch<any>();

  const [password, setPassword] = useState<any>();
  const [confirm, setConfirm] = useState<any>();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordSubmit = () => {
    if (password === confirm) {
      dispatch(confirmThunkAction(password))
        .unwrap()
        .catch((err: Error) => message.error(err.message));
    } else {
      message.error("Пароли не совпадают");
    }
  };

  const handleRecoverPasswordSubmit = () => {
    dispatch(confirmRecoverThunkAction(password))
      .unwrap()
      .catch((err: Error) => {
        message.error(err.message);
      });
  };
  return (
    <div
      style={{
        height: "100svh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="form">
        <h1 className="form__title">Ввод пароля</h1>

        <p className="name-info text-center">Создайте пароль</p>
        <div className="login-page__form-lk">
          <Input.Password
            className="form__input"
            placeholder="Введите  пароль"
            onChange={(v) => setConfirm(v.target.value)}
          />
          <Input.Password
            className="form__input"
            placeholder="Повторите пароль"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            onChange={(v) => setPassword(v.target.value)}
          />

          <Button
            className="form__button"
            onClick={() =>
              recover ? handleRecoverPasswordSubmit() : handlePasswordSubmit()
            }
          >
            Подтвердить
          </Button>
        </div>
      </div>
    </div>
  );
}
