import { LoginData } from "../types";
import { AppResponseHistoryData } from "../models/app-response-history-data";
import store from "../store/rootStore";
import { logoutThunkAction } from "../store/userSlice";
import axios from "axios";
import { router } from "..";

// const baseUrl = "http://192.168.2.225:8081/vodokanal-lk";
const api = axios.create({ baseURL: "/" });

api.interceptors.response.use(
  (response) => {
    console.log(response.status);
    return response;
  },
  (error) => {
    if (401 === error.response.status) {
      let state = store.getState();
      if (state.user.user) {
        store.dispatch(logoutThunkAction());
        router.navigate("/auth/login");
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export async function login(creds: LoginData) {
  try {
    return await api.post(`api/v1/auth/login`, { ...creds });
  } catch (error) {
    return error;
  }
}

export async function logout() {
  try {
    return await api.post(`api/v1/auth/logout`);
  } catch (error) {
    return error;
  }
}

export async function signup(phoneNumber: number, recover = false) {
  try {
    return await api.post(
      `api/v1/auth/${
        recover ? "restore-password" : "registration"
      }/step1/identification`,
      {
        login: phoneNumber,
      }
    );
  } catch (error) {
    return error;
  }
}

export async function verify(code: string, token: string, recover = false) {
  try {
    return await api.post(
      `api/v1/auth/${
        recover ? "restore-password" : "registration"
      }/step2/verification`,
      {
        code,
        token,
      }
    );
  } catch (error) {
    return error;
  }
}

export async function confirm(
  password: string,
  token: string,
  recover = false
) {
  try {
    return await api.post(
      `api/v1/auth/${
        recover ? "restore-password" : "registration"
      }/step3/confirmation`,
      {
        password,
        token,
      }
    );
  } catch (error) {
    return error;
  }
}

export async function resendCode(token: string) {
  try {
    return await api.post(`api/v1/auth/resend-code`, {
      token,
    });
  } catch (error) {
    return error;
  }
}

export async function getUser() {
  try {
    return await api.get(`api/v1/user`);
  } catch (error) {
    return error;
  }
}

export async function getAccounts(uin = "") {
  try {
    if (uin !== "") {
      return await api.get(`/api/v1/accounts/${uin}`);
    }
    return await api.get(`/api/v1/accounts`);
  } catch (error) {
    return error;
  }
}

export async function addAccount(uin: string) {
  try {
    return await api.post("/api/v1/accounts", { uin });
  } catch (error) {
    return error;
  }
}

export async function deleteAccount(uin: string) {
  try {
    return await api.delete(`/api/v1/accounts/${uin}`);
  } catch (error) {
    return error;
  }
}

export async function registerPayment(uin: string, amount: number) {
  try {
    return await api.post("/api/v1/orders/registration", { uin, amount });
  } catch (error) {
    return error;
  }
}

export async function history() {
  return await api.get<AppResponseHistoryData>(`api/v1/orders/history`);
}
