import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addAccount, deleteAccount, getAccounts } from "../api/repository";
import { Account } from "../types";
import { message } from "antd";

export const accountSlice = createSlice({
  name: "account",
  initialState: {
    loading: true,
    accounts: [],
    addAccountModal: false,
    current: null,
  },
  reducers: {
    setModal(state, action) {
      state.addAccountModal = action.payload;
    },
    setCurrent(state, action) {
      state.current = state.accounts[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountsThunkAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAccountsThunkAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.accounts = action.payload;
          if (action.payload.length > 0) {
            state.current = action.payload[0];
          }
        }
      })
      .addCase(addAccountThunkAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(addAccountThunkAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.accounts = action.payload;
          if (action.payload.length > 0) {
            state.current = action.payload[0];
          }
        }
      })
      .addCase(addAccountThunkAction.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteAccountThunkAction.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteAccountThunkAction.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.accounts = action.payload;
          if (action.payload.length > 0) {
            state.current = action.payload[0];
          } else {
            state.current = null;
          }
        }
      });
  },
});

export const getAccountsThunkAction = createAsyncThunk(
  "account/getAccounts",
  async () => {
    const response: any = await getAccounts();
    if (response.status === 200) {
      return response.data.data.accounts;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const addAccountThunkAction = createAsyncThunk(
  "account/addAccount",
  async (uin: string) => {
    const response: any = await addAccount(uin);
    if (response.status === 200) {
      return response.data.data.accounts;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const deleteAccountThunkAction = createAsyncThunk(
  "account/deleteAccount",
  async (uin: string) => {
    const response: any = await deleteAccount(uin);
    if (response.status === 200) {
      return response.data.data.accounts;
    } else {
      throw new Error(response.response.data.error.title);
    }
  }
);

export const { setCurrent, setModal } = accountSlice.actions;
export default accountSlice.reducer;
