import { Button, Input, message } from "antd";
import {  useState } from "react";
import { useDispatch } from "react-redux";
import {  loginThunkAction } from "../store/userSlice";
import { Link,  useNavigate } from "react-router-dom";
import MaskedInput from "antd-mask-input";

export default function LoginPage() {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const [loginName, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (login: string, password: string) => {
    dispatch(loginThunkAction({ login, password }))
      .unwrap()
      .then(() => {
        navigate("/home");
      })
      .catch((err: Error) => {
        message.error(err.message);
      });
  };

  return (
    <div style={{ minHeight: "100svh" ,  width: "100%" , display: "flex", justifyContent:"center" , alignItems:"center"}}>
      <div className="form">
        <h1 className="form__title">Вход</h1>
        <div className="login-page__form">
          <MaskedInput
            mask={"+7 (000) 000-00-00"}
            className="form__input"
            placeholder="Логин"
            onChange={(v) => setLogin(v.unmaskedValue)}
          />
          <Input.Password className="form__input" placeholder="Введите  пароль" type="password"
            onChange={(v) => setPassword(v.target.value)} />
          <Button
            className="form__button"
            onClick={() => handleLogin(loginName, password)}
          >
            Войти
          </Button>
          <Link className="reg-bottom-span" to="/auth/recover/identify">
            Забыли пароль? Восстановить
          </Link>
        </div>
        <div className="reg-bottom_input">
          <span className="reg-bottom-span">
            Если вы впервые у нас, пожалуйста, зарегистрируйтесь
          </span>
          <Link className="form__button" to="/auth/signup/identify">
            Регистрация
          </Link>
        </div>
        <span className="reg-bottom-span">
          Нажимая кнопку "Войти", я предоставляю персональные данные и
          соглашаюсь с обработкой моих персональных данных МУП "Нальчикский водоканал" в
          соответствии с Политикой обработки персональных данных.
        </span>
      </div>
    </div>
  );
}
