import { List, Modal, Table, TableProps, Tag } from "antd";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  CheckCircleOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { PropsWithChildren, useEffect, useState } from "react";
import { historyThunkAction } from "../store/orderSlice";
import { ReceiptSchema } from "../models/receipt-schema";
import { Status, StatusCodeEnum } from "../models/status";

interface DataType {
  key: number;
  paymentDate: string;
  receiptNumber: string;
  uin: string;
  transferAmount: string;
  status: Status;
  preview: string;
}

interface IModalState {
  open: boolean;
  receipt?: ReceiptSchema;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "Дата",
    dataIndex: "paymentDate",
    key: "paymentDate",
    render: (value: string) => <div className="name-info" >{value}</div>,
  },
  {
    title: "Номер чека",
    dataIndex: "receiptNumber",
    key: "receiptNumber",
    render: (value: string) => <div className="name-info" >{value}</div>,
  },
  {
    title: "Лицевой счет",
    dataIndex: "uin",
    key: "uin",
    render: (value: string) => <div className="name-info" >{value}</div>,
  },
  {
    title: "Сумма",
    dataIndex: "transferAmount",
    key: "transferAmount",
    render: (value: string) => <div className="name-info" >{value}</div>,
  },
  {
    title: "Статус",
    dataIndex: "status",
    key: "status",
    render: (value: Status) => <Tag color="green">{value.message}</Tag>,
  },
];

export default function HistoryPage() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.order.loading);
  const history = useAppSelector((state) => state.order.history) || [];
  const [state, setState] = useState<IModalState>({ open: false });

  useEffect(() => {
    dispatch(historyThunkAction());
  }, [dispatch]);

  const dataSource =
    history.map<DataType>((item, index) => ({
      key: index,
      paymentDate: new Date(item.paymentDate)
        .toLocaleString("ru-RU")
        .replace(",", ""),
      receiptNumber: item.receiptNumber,
      uin: item.uin,
      transferAmount: (item.transferAmount / 100).toLocaleString("ru-RU"),
      status: item.status,
      preview: "",
    })) || [];

  return (
    <>
      <div className="receipt-preview">
        <Table
          loading={loading}
          pagination={false}
          dataSource={dataSource}
          columns={[
            ...columns!,
            {
              title: "",
              dataIndex: "preview",
              key: "preview",
              render: (value, record, index) => (
                <FileSearchOutlined
                onClick={() =>
                  setState({
                    open: true,
                    receipt: history[index],
                  })
                }
                />
              ),
            },
          ]}
        />
      </div>
      <div className="receipt-preview">
        <List
          size="small"
          header={false}
          footer={false}
          bordered
          dataSource={dataSource}
          renderItem={(item, index) => (
            <List.Item
              style={{
                padding: "5px 10px",
                fontFamily: "Manrope",
                fontWeight: "600",
              }}
              onClick={() =>
                setState({
                  open: true,
                  receipt: history[index],
                })
              }
            >
              <div className="list_item" >
                {item.status.code === StatusCodeEnum.SUCCESS ? (
                  <CheckCircleOutlined
                    style={{ color: "#2162be", fontSize: "20px" }}
                  />
                ) : (
                  <InfoCircleOutlined
                    style={{ color: "#fae240", fontSize: "20px" }}
                    twoToneColor="#2162be"
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div >{item.paymentDate}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      №{item.receiptNumber} <div>л.с - {item.uin}</div>
                    </div>
                  </div>
                  <div style={{ fontSize: "16px" }}>
                    {item.transferAmount} р
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
      </div>
      {state.receipt && (
        <Modal
          title="Детали платежа"
          style={{textAlign:"center"}}
          open={state.open}
          onCancel={() => setState({ ...state, open: false })}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
        >
          <div>
            <ModalRow>
              <div>Статус:</div>
              <div >{state.receipt.status.message}</div>
            </ModalRow>
            <ModalRow>
              <div>Назначение:</div>
              <div>{state.receipt.purpose}</div>
            </ModalRow>
            <ModalRow>
              <div>Дата платежа:</div>
              <div>
                {new Date(state.receipt.paymentDate)
                  .toLocaleString("ru-RU")
                  .replace(",", "")}
              </div>
            </ModalRow>
            <ModalRow>
              <div>Номер чека:</div>
              <div>{state.receipt.receiptNumber}</div>
            </ModalRow>
            <ModalRow>
              <div>Лицевой счет:</div>
              <div>{state.receipt.uin}</div>
            </ModalRow>
            <ModalRow>
              <div>ФИО:</div>
              <div>{state.receipt.fio}</div>
            </ModalRow>
            <ModalRow>
              <div>Адрес:</div>
              <div>{state.receipt.address}</div>
            </ModalRow>
            <ModalRow>
              <div>Сумма перевода:</div>
              <div>
                {(state.receipt.transferAmount / 100).toLocaleString("ru-RU")} ₽
              </div>
            </ModalRow>
            <ModalRow>
              <div>Сумма комиссии:</div>
              <div>
                {(state.receipt.commissionAmount / 100).toLocaleString("ru-RU")}{" "}
                ₽
              </div>
            </ModalRow>
            <ModalRow>
              <div>Итого:</div>
              <div>
                {(state.receipt.totalAmount / 100).toLocaleString("ru-RU")} ₽
              </div>
            </ModalRow>
            {state.receipt.pan && (
              <ModalRow>
                <div>Номер карты:</div>
                <div>{state.receipt.pan}</div>
              </ModalRow>
            )}
            <div className="table-info">
            <h1 className="title_table">Информация о получателе</h1>
              {state.receipt.requisites.map((requisite) => (
                <ModalRow>
                  <div className="table-info_text_title">
                    {requisite.title}:{" "}<div className="table-info_text_value">{requisite.value}</div>
                  </div>
                </ModalRow>
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

function ModalRow(props: PropsWithChildren) {
  return (
    <div style={{ display: "flex", justifyContent: "space-between", }}>
      {props.children}
    </div>
  );
}
