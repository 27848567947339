import store from "./store/rootStore";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  Outlet,
  redirect,
  RouterProvider,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { Provider } from "react-redux";
import MainLayout from "./components/Layout";
import HomePage from "./pages/HomePage";
import AuthLayout from "./components/AuthLayout";
import SignUpPage from "./pages/SignUpPage";
import VerifyPage from "./pages/VerifyPage";
import ConfirmPage from "./pages/ConfirmPage";
import HistoryPage from "./pages/HistoryPage";
import { getUserThunkAction } from "./store/userSlice";

export const router = createBrowserRouter([
  {
    path: "/",
    loader: async () => {
      await store
        .dispatch(getUserThunkAction())
        .unwrap()
        .catch((err: Error) => console.log(err.message));
      if (store.getState().user.user) {
        return null;
      } else {
        return redirect("/auth/login");
      }
    },
    element: <MainLayout />,
    children: [
      { path: "home", element: <HomePage /> },
      { path: "history", element: <HistoryPage /> },
    ],
  },
  {
    path: "/auth",
    loader: async () => {
      await store
        .dispatch(getUserThunkAction())
        .unwrap()
        .catch((err: Error) => console.log(err.message));
      if (store.getState().user.user) {
        return redirect("/home");
      } else {
        return null;
      }
    },
    element: <AuthLayout />,
    children: [
      { path: "login", element: <LoginPage /> },
      {
        path: "signup",
        element: <Outlet />,
        children: [
          { path: "identify", element: <SignUpPage /> },
          { path: "verify", element: <VerifyPage /> },
          { path: "confirm", element: <ConfirmPage /> },
        ],
      },
      {
        path: "recover",
        element: <Outlet />,
        children: [
          { path: "identify", element: <SignUpPage recover /> },
          { path: "verify", element: <VerifyPage recover /> },
          { path: "confirm", element: <ConfirmPage recover /> },
        ],
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
